const DEFAULT_PAGE_SIZE = 10;
const MEDIUM_PAGE_SIZE = 25;
const LARGE_PAGE_SIZE = 50;
const MAX_PAGE_SIZE = 100;
const ALL_PAGE_SIZES = [DEFAULT_PAGE_SIZE, MEDIUM_PAGE_SIZE, LARGE_PAGE_SIZE, MAX_PAGE_SIZE];
const DEFAULT_SDK_SIZE = 100;

export {
    DEFAULT_PAGE_SIZE,
    ALL_PAGE_SIZES,
    DEFAULT_SDK_SIZE,
}